import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: boolean;
};

export default function UserOfferFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [hasOffer, setHasOffer] = useState<boolean>(prevFilter || false);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);

  const onAddFilter = (val: boolean) => {
    setHasOffer(val);
    onFilterChange({
      has_offer: val,
    });
  };

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {hasOffer && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {1}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[250px]">
          <div className="flex flex-col gap-y-3 w-full overflow-auto">
            <div className="flex justify-between items-center">
              <div className="text-lg font-medium">Offer Treatment</div>
            </div>
            <hr />
            <div className="flex justify-start items-center flex-wrap gap-2 w-full">
              <div
                key="offer"
                className="w-full flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                onClick={() => onAddFilter(!hasOffer)}
              >
                <Checkbox checked={hasOffer} />
                <div className="text-[12px] font-normal">Has Offers</div>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
