import {
  BorrowerCallNode,
  BorrowerHistoryNode,
  BorrowerNode,
  CallLog,
  CallLogDetails,
  CallType,
  PhoneNumberNode,
} from 'src/typings/call.types';
import { Client } from '../axios/clientFactory';
import { networkHandler } from '../axios/networkHandler';
import { CompanyNode } from 'src/typings/user.types';
import has from 'lodash/has';
import ms from 'ms';
import isEmpty from 'lodash/isEmpty';
import { BorrowerStatsNode, CallStatsNode, PaidStatsNode, StatsNode, StatsPayload } from 'src/typings/stats.types';
import { UploadBorrowerInfoNode, UploadPaymentNode, UploadType } from 'src/typings/dataUpload.types';
import { Languages } from 'src/constants/language';

export type CallLogResponse = {
  items: CallLog[];
  page: number;
  pages: number;
  size: number;
  total: number;
};

export const getCallLogs = async ({
  page,
  size,
  callType,
  companyId,
  params,
  duration,
  start_date,
  end_date,
  dpd_min,
  dpd_max,
  language,
  has_offer,
}: {
  page: number;
  size: number;
  callType: CallType;
  companyId?: CompanyNode['id'];
  params?: object;
  duration?: number;
  start_date?: string;
  end_date?: string;
  dpd_min?: number;
  dpd_max?: number;
  language?: Languages;
  has_offer?: boolean;
}): Promise<CallLogResponse> => {
  try {
    let url = `/calls?call_type=${callType}&page=${page}&size=${size}`;
    if (companyId) {
      url = url + `&company_id=${companyId}`;
    }
    if (duration) {
      url = url + `&duration=${duration}`;
    }
    if (start_date) {
      url = url + `&call_start=${start_date}`;
    }
    if (end_date) {
      url = url + `&call_end=${end_date}`;
    }
    if (dpd_min) {
      url = url + `&dpd_min=${dpd_min}`;
    }
    if (dpd_max) {
      url = url + `&dpd_max=${dpd_max}`;
    }
    if (language) {
      url = url + `&language=${language}`;
    }
    if (has_offer) {
      url = url + `&has_offer=${has_offer}`;
    }
    if (params) {
      const hasDuration = has(params, 'duration');
      const hasDPDMin = has(params, 'dpd_min');
      const hasDPDMax = has(params, 'dpd_max');
      const hasStartDate = has(params, 'start_date');
      const hasEndDate = has(params, 'end_date');
      const hasLanguage = has(params, 'language');
      const hasOffer = has(params, 'has_offer');
      if (hasDuration) {
        delete params.duration;
      }
      if (hasStartDate) {
        delete params.start_date;
      }
      if (hasEndDate) {
        delete params.end_date;
      }
      if (hasDPDMin) {
        delete params.dpd_min;
      }
      if (hasDPDMax) {
        delete params.dpd_max;
      }
      if (hasLanguage) {
        delete params.language;
      }
      if (hasOffer) {
        delete params.hasOffer;
      }

      if (!isEmpty(params)) {
        let val = encodeURIComponent(JSON.stringify({ ...params }));
        url = url + `&params=${val}`;
      }
    }
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallLogs', error);
    throw error;
  }
};

export const getCallLogDetails = async (id: CallLog['id']): Promise<CallLogDetails> => {
  try {
    const url = `/calls/${id}`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallLogDetails', error);
    throw error;
  }
};

export const updateCallLogDetails = async (
  id: CallLog['id'],
  payload: Partial<CallLogDetails>,
): Promise<CallLogDetails> => {
  try {
    const url = `/calls/${id}`;
    return await networkHandler('put', url, payload, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: updateCallLogDetails', error);
    throw error;
  }
};

export const getCallingAgents = async (): Promise<string[]> => {
  try {
    const url = `/agents`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallingAgents', error);
    throw error;
  }
};

export const makeCall = async (payload: any) => {
  try {
    const url = `/directcall`;
    return await networkHandler('post', url, { ...payload }, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: makeCall', error);
    throw error;
  }
};

export const updateCallInfo = async (id: BorrowerCallNode['id'], payload: any) => {
  try {
    const url = `/borrowercalls/${id}`;
    return await networkHandler('put', url, { ...payload }, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: updateCallInfo', error);
    throw error;
  }
};

export const getCompanyList = async (): Promise<CompanyNode[]> => {
  try {
    const url = `/companies`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCompanyList', error);
    throw error;
  }
};

export const getScheduledCallInfo = async (borrowercalls_id: BorrowerCallNode['id']) => {
  try {
    const url = `/scheduledcalls/${borrowercalls_id}`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: updateCallInfo', error);
    throw error;
  }
};

export const deleteScheduledCall = async (borrowercalls_id: BorrowerCallNode['id']) => {
  try {
    const url = `/scheduledcalls/${borrowercalls_id}`;
    return await networkHandler('delete', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: deleteScheduledCall', error);
    throw error;
  }
};

export const getPhoneNumbers = async (): Promise<PhoneNumberNode[]> => {
  try {
    const url = `/phonenumbers`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getPhoneNumbers', error);
    throw error;
  }
};

export const addPhoneNumber = async (payload: Omit<PhoneNumberNode, 'id'>) => {
  try {
    const url = `/phonenumbers`;
    return await networkHandler('post', url, { ...payload }, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: addPhoneNumber', error);
    throw error;
  }
};

export const updatePhoneNumber = async (id: PhoneNumberNode['id'], payload: Pick<PhoneNumberNode, 'spam'>) => {
  try {
    const url = `/phonenumbers/${id}`;
    return await networkHandler('put', url, { ...payload }, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: updatePhoneNumber', error);
    throw error;
  }
};

export const deletePhoneNumber = async (id: PhoneNumberNode['id']) => {
  try {
    const url = `/phonenumbers/${id}`;
    return await networkHandler('delete', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: deletePhoneNumber', error);
    throw error;
  }
};

export const getBorrowerCallHistory = async (borrowerId: BorrowerNode['id']): Promise<BorrowerHistoryNode[]> => {
  try {
    const url = `/borrowercalls?borrower_id=${borrowerId}&columns=call_sid,call_start,call_end,call_from,telephony,intent,duration,agent`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getBorrowerCallHistory', error);
    throw error;
  }
};

export const getPickStats = async (payload: StatsPayload): Promise<StatsNode[]> => {
  try {
    let url = `/metrics?company_id=${payload.company_id}&metric_type=${payload.metric_type}&start_date=${payload.start_date}`;
    if (payload?.end_date) {
      url = url + `&end_date=${payload.end_date}`;
    }
    if (payload?.telephony) {
      url = url + `&telephony=${payload.telephony}`;
    }
    if (payload?.time_of_day) {
      url = url + `&time_of_day=${payload.time_of_day}`;
    }

    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getPickStats', error);
    throw error;
  }
};

export const getBorrowerStats = async (payload: StatsPayload): Promise<BorrowerStatsNode[]> => {
  try {
    let url = `/metrics?company_id=${payload.company_id}&metric_type=${payload.metric_type}&start_date=${payload.start_date}`;
    if (payload?.end_date) {
      url = url + `&end_date=${payload.end_date}`;
    }
    if (payload?.telephony) {
      url = url + `&telephony=${payload.telephony}`;
    }
    if (payload?.time_of_day) {
      url = url + `&time_of_day=${payload.time_of_day}`;
    }

    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getBorrowerStats', error);
    throw error;
  }
};

export const getCallStats = async (payload: StatsPayload): Promise<CallStatsNode[]> => {
  try {
    let url = `/metrics?company_id=${payload.company_id}&metric_type=${payload.metric_type}&start_date=${payload.start_date}`;
    if (payload?.end_date) {
      url = url + `&end_date=${payload.end_date}`;
    }
    if (payload?.telephony) {
      url = url + `&telephony=${payload.telephony}`;
    }
    if (payload?.time_of_day) {
      url = url + `&time_of_day=${payload.time_of_day}`;
    }

    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallStats', error);
    throw error;
  }
};

export const getPaidStats = async (payload: StatsPayload): Promise<PaidStatsNode[]> => {
  try {
    let url = `/metrics?company_id=${payload.company_id}&metric_type=${payload.metric_type}&start_date=${payload.start_date}`;
    if (payload?.end_date) {
      url = url + `&end_date=${payload.end_date}`;
    }
    if (payload?.telephony) {
      url = url + `&telephony=${payload.telephony}`;
    }
    if (payload?.time_of_day) {
      url = url + `&time_of_day=${payload.time_of_day}`;
    }

    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getPaidStats', error);
    throw error;
  }
};

export const getCallEmotionAnalysis = async (id: CallLog['id']): Promise<string> => {
  try {
    const url = `/calls/${id}/analysis`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallEmotionAnalysis', error);
    throw error;
  }
};

export const getCallRecording = async (id: CallLog['id']): Promise<string> => {
  try {
    const url = `/calls/${id}/recording`;
    return await networkHandler('get', url, {}, Client.CORE, 0);
  } catch (error) {
    console.error('ERROR: getCallRecording', error);
    throw error;
  }
};

export const uploadBorrowerData = async (
  companyId: CompanyNode['id'],
  payload: UploadBorrowerInfoNode,
): Promise<string> => {
  try {
    const url = `/partner/pushCaseData?company_id=${companyId}`;
    return await networkHandler('post', url, { ...payload }, Client.CORE, ms('1200sec'));
  } catch (error) {
    console.error('ERROR: uploadBorrowerData', error);
    throw error;
  }
};

export const uploadUpdatedPaymentData = async (
  companyId: CompanyNode['id'],
  payload: UploadPaymentNode,
): Promise<string> => {
  try {
    const url = `/partner/pushPaymentInfo?company_id=${companyId}`;
    return await networkHandler('post', url, { ...payload }, Client.CORE, ms('1200sec'));
  } catch (error) {
    console.error('ERROR: uploadUpdatedPaymentData', error);
    throw error;
  }
};

export const uploadFile = async (
  companyId: CompanyNode['id'],
  uploadType: UploadType,
  formData: FormData,
): Promise<string> => {
  try {
    const url = `/partner/${uploadType}/batchUpload?company_id=${companyId}`;
    return await networkHandler('post', url, formData, Client.CORE, ms('1200sec'));
  } catch (error) {
    console.error(`ERROR: ${uploadType} uploadFile`, error);
    throw error;
  }
};
