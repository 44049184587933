import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { languageOptions, Languages } from 'src/constants/language';
import { twMerge } from 'tailwind-merge';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: Languages;
};

export default function UserLanguageFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState<Languages | null>(prevFilter || null);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);

  const handleChange = (key: Languages) => {
    let val = null;
    if (selectedFilter === key) {
      setSelectedFilter(null);
    } else {
      val = key;
      setSelectedFilter(val);
    }
    onFilterChange({ language: val });
  };

  const onClearAll = () => {
    setSelectedFilter(null);
    onFilterChange({ language: null });
  };

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilter && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {1}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[250px]">
          <div className="w-full flex justify-between items-center">
            <div className="text-lg font-medium">Language</div>
            <div
              onClick={onClearAll}
              className={twMerge(
                'text-sm cursor-pointer text-primaryButton',
                selectedFilter === null ? 'text-gray-400 cursor-not-allowed' : '',
              )}
            >
              Clear all
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col h-48 overflow-auto">
              {languageOptions.map((language) => {
                const isSelected = selectedFilter === language.id;

                return (
                  <div
                    key={language.id}
                    className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                    onClick={() => handleChange(language.id)}
                  >
                    <Checkbox checked={isSelected} />
                    <div className="text-[12px] font-normal">{language.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
