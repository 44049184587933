import { Languages } from 'src/constants/language';
import { CompanyNode } from './user.types';

export type CallLog = {
  id: string;
  borrower_name: BorrowerNode['name'];
  borrower_phone_number: BorrowerNode['phone_number'];
  borrower_language: Languages | null;
  borrowercalls_id: BorrowerCallNode['id'];
  call_sid: string;
  call_to: number;
  duration: number;
  call_start: string;
  call_end: string;
  latency: number;
  disconnected_by: boolean;
  intent: number;
  time_to_call: string;
  call_submit_time: string;
  call_scheduled_time: string;
  previous_call_sid: string;
  knowl_requested_time: string;
  manual_intent: number;
  dpd: number;
  due_date: string;
  offer_treatment: string | null;
};

export type BorrowerNode = {
  amount_due: number;
  company_id: CompanyNode['id'];
  due_date: string;
  gender: string;
  id: number;
  loan_id: string;
  name: string;
  offer_amount: string;
  offer_expiry: string;
  penal_charges: number;
  phone_number: string;
  total_amount: number;
  customer_metadata: {
    language: Languages;
    payment_link?: string;
    offer_treatment?: string;
  };
};

export type BorrowerDetailNode = {
  Borrower: BorrowerNode;
  BorrowerCall: BorrowerCallNode;
};

export type CallLogDetails = CallLog & {
  agent: string;
  telephony: Telephony;
  call_from: string;
  customer_requested_time: string;
  prompt?: string;
  recording: string;
  transcript: string;
  reason: string;
  borrower: BorrowerNode;
  latency_stats: LatencyStats;
  // call_quality?: CallQuality;
  borrower_calls?: BorrowerCallNode;
  ptp: boolean | null;
  ptp_datetime: string | null;
  ptp_reason: string | null;
  call_summary: string | null;
  manual_intent: number;
  agent_next_action: string | null;
  delay_reason: string | null;
  borrower_sentiment: string | null;
  borrower_sentiment_reason: string | null;
  borrower_request: string | null;
  call_quality: CallQualityNode;
};

export type CallQualityNode = {
  call_quality_analysis_manual: string;
  call_quality_score_manual: number;
  escalation_triggers: string;
  level_engagement: number;
  reason_for_level_of_engagement: string;
  information_provided: string;
};

export type BorrowerCallNode = {
  id: string;
  call_sid: CallLog['call_sid'];
  call_scheduled_time: string;
  previous_call_sid: string;
  borrower_id: string;
  call_submit_time: string;
};

export enum Speaker {
  Agent = 'Agent',
  User = 'User',
}

export enum Telephony {
  Plivo = 'plivo',
  Exotel = 'exotel',
}

export type TranscriptNode = {
  Message: string;
  Speaker: Speaker;
  Time: string;
  LLM: string;
  Confidence: number;
  Latency: {
    STT?: number;
    TTS?: number;
    LLM?: number;
  };
};

export type BorrowerHistoryNode = Omit<CallLogDetails, 'borrower' | 'borrower_calls'>;

export const IntentReasonMap: { [key: number]: string } = {
  1: 'PHONE_NOT_PICKED',
  2: 'PHONE_PICKED',
  3: 'PHONE_PICKED_CALL_LATER',
  4: 'PHONE_PICKED_BY_SOMEONE_ELSE',
  5: 'PHONE_PICKED_LANGUAGE_PROBLEM',
  6: 'PHONE_PICKED_NO_RESPONSE',
  7: 'PHONE_PICKED_DISCONNECTED',
  8: 'PHONE_PICKED_ANSWERING_MACHINE',
  9: 'PHONE_NOT_REACHABLE',
  10: 'PHONE_SWITCHED_OFF',
  11: 'PHONE_BUSY',
  12: 'PHONE_BUSY_ANOTHER_CALL',
  13: 'PHONE_WRONG_NUMBER',
  14: 'PHONE_DIVERTED',
  15: 'PHONE_NOT_IN_SERVICE',
  16: 'PHONE_TEMP_DISCONNECTED',
  17: 'PHONE_OTHER',
  18: 'PHONE_AGENT_NOT_PICKED',
  19: 'PHONE_AGENT_NOT_REACHABLE',
  20: 'PHONE_AGENT_OUT_OF_SERVICE',
  21: 'PHONE_AGENT_SWITCHED_OFF',
  22: 'PHONE_AGENT_CANCELED',
  23: 'PHONE_SERVER_FAILED',
  24: 'PHONE_REJECTED',
  25: 'NO_INTENT_TO_PAY',
  26: 'PAY_LATER',
  27: 'BORROWER_PAID',
  28: 'PAY_TODAY',
  29: 'BORROWER_DENIED_LOAN',
};

export enum CallType {
  REGULAR = 'regular',
  FUTURE = 'future',
  SCHEDULED = 'scheduled',
  GARBAGE = 'garbage',
}

export type CallQuality = {
  call_quality_score_ai: number;
  call_quality_analysis_ai: string;
  call_quality_score_manual: number;
  call_quality_analysis_manual: string;
};

export type LatencyStats = {
  avg_latency: number;
  avg_llm_latency: number;
  avg_stt_latency: number;
  avg_tts_latency: number;
  max_latency: number;
  max_llm_latency: number;
  max_stt_latency: number;
  max_tts_latency: number;
  median_latency: number;
  median_llm_latency: number;
  median_stt_latency: number;
  median_tts_latency: number;
  min_latency: number;
  min_llm_latency: number;
  min_stt_latency: number;
  min_tts_latency: number;
};

export type PhoneNumberNode = {
  id: string;
  phone_number: string;
  telephony: Telephony;
  spam: boolean;
};

export type CallDetailsTab = {
  id: number;
  name: string;
  isAllowed: boolean;
};

export type PreviousCallContextNode = {
  delay_reason: string;
  borrower_attitude: string;
};
